import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

export default function Banner(props) {
    const name = props.data.node?.name;
    let pack;
    let description;

    const Bold = ({ children }) => <span className="bold">{children}</span>
    const Text = ({ children }) => <p className="align-center pb-4">{children}</p>

    const options = {
        renderMark: {
            [MARKS.BOLD]: text => <Bold>{text}</Bold>,
        },
        renderNode: {
            [BLOCKS.PARAGRAPH]: (node, children) => <Text className="py-10">{children}</Text>,
            [BLOCKS.EMBEDDED_ASSET]: node => {
                return (
                    <>
                        <h2>Embedded Asset</h2>
                        <pre>
                            <code>{JSON.stringify(node, null, 2)}</code>
                        </pre>
                    </>
                )
            },
        },
    }

    description = renderRichText(props.data.node?.description, options)
    pack = renderRichText(props.data.node?.packageRichText || '', options)

    return (
        <div className="bg-white">
            <div className="">
                <div className="flex flex-col p-4 sm:p-8 ">
                    <p className="text-3xl md:text-3xl text-flamingo-800 lg:text-4xl  py-2 sm:py-4 md:py-6 lg:py-8">{name}</p>
                </div>
                <div class="grid grid-cols-1 sm:px-8 gap-4">
                    <p className="p-4 sm:p-0 text-dustypurple  lg:text-lg">{description}</p>
                    <div class="">
                        <div class="w-full h-60 grid grid-cols-3 grid-rows-2 gap-2">
                            {props.data && props.data.node.photos.map((photo, i) => (
                                <GatsbyImage
                                    imgClassName="sm:rounded-md md:rounded-lg"
                                    className={i === 0 ? `relative col-span-3 row-span-3 md:col-span-2` : `relative hidden md:block`}
                                    image={photo.thumb} />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
            <div className="p-4 sm:px-8">
                <div className="">
                    <div className="flex flex-col">
                        <p className="text-xl md:text-2xl text-flamingo-800 lg:text-3xl pb-2 sm:pb-4 md:pb-6 lg:pb-8">Cennik</p>
                    </div>
                    <div className=" text-gray-600">
                        {pack}
                    </div>
                </div>
            </div>
        </div>
    )
}



