import React from "react"
import Layout from "../components/layout"
import Header from "../components/header"
import Offer from "../components/offer"
import { graphql } from 'gatsby'

export default function Offers({ data }) {
  console.log("WTF: ", data);
  const { edges } = data.allContentfulPhotoShoot

  return (
    <Layout>
      <div className="bg-flamingo-100 p-4 sm:p-10 md:p-16  h-full">
        <Header headerText="Oferta" />
        <div className="grid grid-flow-row grid-cols-1 lg:grid-cols-2 gap-8">

          {edges && edges.map(e => (
            <Offer data={e}></Offer>
          ))}
        </div>
      </div>
    </Layout>
  )
}


export const query = graphql`
query {
  allContentfulPhotoShoot {
    edges {
      node {
        name
        packageRichText {
          raw
        }
        description {
          raw
        }
        photos {
          thumb: gatsbyImageData(
            width: 1600
            height: 900
            placeholder: BLURRED
          )
        }
      }
    }
  }
}
`
